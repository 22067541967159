import { OPEN_TOAST, CLOSE_TOAST } from './actions'

const initialState = {
  open: false,
  variant: null,
  content: null,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_TOAST: {
      const { variant, content } = action.data

      return {
        ...state,
        open: true,
        variant,
        content,
      }
    }
    case CLOSE_TOAST: {
      return {
        ...state,
        open: false,
      }
    }
    default:
      return state
  }
}
