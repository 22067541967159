export const OPEN_TOAST = 'OPEN_TOAST'
export const CLOSE_TOAST = 'CLOSE_TOAST'

export function openToast(variant, content) {
  return function dispatcher(dispatch) {
    dispatch({ type: OPEN_TOAST, data: { variant, content } })
  }
}

export function closeToast() {
  return function dispatcher(dispatch) {
    dispatch({ type: CLOSE_TOAST })
  }
}
