export const responseExample = {
  data: {
    count: 5,
    pagination: {
      type: 'offset',
      limit: 7,
      start: 0,
    },
    data: [
      {
        createdAt: '2019-02-28T07:17:28.716Z',
        deletedAt: null,
        company: 'Roam Creative Ltd',
        country: 'New Zealand',
        department: 'Web development',
        disabled: false,
        email: null,
        jobTitle: 'FE developer',
        joinDate: '2017-05-15T07:17:28.716Z',
        mainOfficeLocation: 'Auckland',
        name: 'Anton Lebedev',
        team: 'Titan',
        updatedAt: '2019-02-28T07:17:28.716Z',
        uuid: 'c02ce930-0fce-4b29-8be0-7b1aa546d69b',
      },
      {
        createdAt: '2019-02-28T07:17:28.716Z',
        deletedAt: null,
        company: 'Roam Creative Ltd',
        country: 'New Zealand',
        department: 'Web development',
        disabled: false,
        email: null,
        jobTitle: 'FE developer',
        joinDate: '2017-05-15T07:17:28.716Z',
        mainOfficeLocation: 'Auckland',
        name: 'Anton Lebedev',
        team: 'Titan',
        updatedAt: '2019-02-28T07:17:28.716Z',
        uuid: 'c02ce930-1fce-4b29-8be0-7b1aa546d69b',
      },
      {
        createdAt: '2019-02-28T07:17:28.716Z',
        deletedAt: null,
        company: 'Roam Creative Ltd',
        country: 'New Zealand',
        department: 'Web development',
        disabled: false,
        email: null,
        jobTitle: 'FE developer',
        joinDate: '2017-05-15T07:17:28.716Z',
        mainOfficeLocation: 'Auckland',
        name: 'Anton Lebedev',
        team: 'Titan',
        updatedAt: '2019-02-28T07:17:28.716Z',
        uuid: 'c02ce930-2fce-4b29-8be0-7b1aa546d69b',
      },
      {
        createdAt: '2019-02-28T07:17:28.716Z',
        deletedAt: null,
        company: 'Roam Creative Ltd',
        country: 'New Zealand',
        department: 'Web development',
        disabled: false,
        email: null,
        jobTitle: 'FE developer',
        joinDate: '2017-05-15T07:17:28.716Z',
        mainOfficeLocation: 'Auckland',
        name: 'Anton Lebedev',
        team: 'Titan',
        updatedAt: '2019-02-28T07:17:28.716Z',
        uuid: 'c02ce930-3fce-4b29-8be0-7b1aa546d69b',
      },
      {
        createdAt: '2019-02-28T07:17:28.716Z',
        deletedAt: null,
        company: 'Roam Creative Ltd',
        country: 'New Zealand',
        department: 'Web development',
        disabled: false,
        email: null,
        jobTitle: 'FE developer',
        joinDate: '2017-05-15T07:17:28.716Z',
        mainOfficeLocation: 'Auckland',
        name: 'Anton Lebedev',
        team: 'Titan',
        updatedAt: '2019-02-28T07:17:28.716Z',
        uuid: 'c02ce930-4fce-4b29-8be0-7b1aa546d69b',
      },
    ],
  },
}
