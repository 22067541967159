import styled from 'styled-components'
import { Button } from '@material-ui/core'

export const StyledButton = styled(Button).attrs({
  variant: 'contained',
})`
  && {
    width: 200px;
    height: 50px;
    background-color: #42f5b0;
    color: #e942f5;
    margin: 20px;
    align-self: center;
    font-family: 'Comic Sans MS';
  }
`
