import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useRouter } from 'react-router5'
import { ACCOUNT, HOME } from 'src/router/routes'
import { Typography, Select, MenuItem, Input } from '@material-ui/core'
import { accountDetails } from 'src/utils/accounts'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import { StyledButton } from 'src/components/atoms/StyledButton/StyledButton'
import { waitTime } from 'src/utils/waitTime'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 60%;
  min-height: 100vh;
  margin: auto;
  justify-content: center;
  align-content: center;
  background-color: #e942f5;
`

const BankName = styled(Typography).attrs({ variant: 'h1' })`
  && {
    font-size: 100px;
    font-weight: 900;
    align-self: center;
    padding-bottom: 30px;
    color: #42f5b0;
    font-family: 'Comic Sans MS';
  }
`

const StyledSelect = styled(Select)`
  && {
    width: 250px;
    height: 50px;
    margin: 0 50px 40px 50px;
  }
`

const StyledInput = styled(Input)`
  && {
    width: 250px;
    height: 50px;
    margin: 0 50px 40px 50px;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const InputName = styled(Typography).attrs({ variant: 'h3' })`
  && {
    margin: 0 50px;
    color: #42f5b0;
    font-family: 'Comic Sans MS';
  }
`

const Transfers = () => {
  const [fromAccount, setFromAccount] = useState('')
  const [toAccount, setToAccount] = useState('')
  const [amount, setAmount] = useState('')
  const [formHasData, setFormHasData] = useState(false)

  const [loading, setLoading] = useState(false)
  const router = useRouter()

  const handleSetFromAccount = (event) => {
    setFromAccount(event.target.value)
  }

  const handleSetToAccount = (event) => {
    setToAccount(event.target.value)
  }

  const handleAmountChange = (event) => {
    setAmount(event.target.value)
  }

  const handleTransfer = () => {
    setLoading(true)
    setTimeout(() => router.navigate(ACCOUNT), waitTime())
  }

  const handleLogout = () => {
    router.navigate(HOME)
  }

  useEffect(
    () => {
      if (fromAccount && toAccount && amount) {
        setFormHasData(true)
      }
    },
    [amount, fromAccount, toAccount]
  )

  return (
    <Container>
      <BankName>transfers</BankName>
      <Row>
        <Column>
          <InputName>Transfer from:</InputName>
          <StyledSelect value={fromAccount} onChange={handleSetFromAccount}>
            {accountDetails.map((account) => (
              <MenuItem key={account.accountNumber} value={account.accountName}>
                {account.accountName}
              </MenuItem>
            ))}
          </StyledSelect>
        </Column>
        <Column>
          <InputName>Transfer to:</InputName>
          <StyledInput
            type="number"
            onChange={handleSetToAccount}
            value={toAccount}
          />
        </Column>
        <Column>
          <InputName>Amount:</InputName>
          <StyledInput
            value={amount}
            onChange={handleAmountChange}
            type="number"
          />
        </Column>
      </Row>
      <Row>
        <StyledButton disabled={!formHasData} onClick={handleTransfer}>
          {loading ? <LoadingSpinner /> : 'Confirm'}
        </StyledButton>
        <StyledButton onClick={handleLogout}>Logout</StyledButton>
      </Row>
    </Container>
  )
}

Transfers.propTypes = {
  router: PropTypes.object,
}

export default React.memo(Transfers)
