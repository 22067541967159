export const accountDetails = [
  {
    accountName: 'Everyday',
    type: 'Everyday',
    balance: 1000,
    accountNumber: '12-3456-7891011-00',
  },
  {
    accountName: 'Savings #1',
    type: 'Savings',
    balance: 9999,
    accountNumber: '12-3456-7891012-00',
  },
  {
    accountName: 'Savings #2',
    type: 'Savings',
    balance: 20,
    accountNumber: '12-3456-7891013-00',
  },
  {
    accountName: 'Petty cash',
    type: 'Everyday',
    balance: 2,
    accountNumber: '12-3456-7891014-00',
  },
]

export const transactions = {
  1: [
    {
      date: `12 / 12 / 19`,
      amount: 12.0,
      details: 'Pizza',
    },
    {
      date: `13 / 12 / 19`,
      amount: 13.0,
      details: 'Pie',
    },
    {
      date: `14 / 12 / 19`,
      amount: 14.0,
      details: 'Parma',
    },
    {
      date: `15 / 12 / 19`,
      amount: 15.0,
      details: 'Pineapple',
    },
    {
      date: `16 / 12 / 19`,
      amount: 16.0,
      details: 'Pasta',
    },
    {
      date: `17 / 12 / 19`,
      amount: 17.0,
      details: 'Pickles',
    },
    {
      date: `18 / 12 / 19`,
      amount: 18.0,
      details: 'Pastry',
    },
  ],
  2: [
    {
      date: `12 / 12 / 19`,
      amount: 12.0,
      details: 'Bread',
    },
    {
      date: `13 / 12 / 19`,
      amount: 13.0,
      details: 'Biscuits',
    },
    {
      date: `14 / 12 / 19`,
      amount: 14.0,
      details: 'Bananas',
    },
    {
      date: `15 / 12 / 19`,
      amount: 15.0,
      details: 'Blanched Almonds',
    },
    {
      date: `16 / 12 / 19`,
      amount: 16.0,
      details: 'Burgers',
    },
    {
      date: `17 / 12 / 19`,
      amount: 17.0,
      details: 'Bonoffee',
    },
    {
      date: `18 / 12 / 19`,
      amount: 18.0,
      details: 'Vitamin B',
    },
  ],
  3: [
    {
      date: `12 / 12 / 19`,
      amount: 12.0,
      details: 'Tango',
    },
    {
      date: `13 / 12 / 19`,
      amount: 13.0,
      details: 'Toffee',
    },
    {
      date: `14 / 12 / 19`,
      amount: 14.0,
      details: 'Tamali',
    },
    {
      date: `15 / 12 / 19`,
      amount: 15.0,
      details: 'Towels',
    },
  ],
  4: [
    {
      date: `12 / 12 / 19`,
      amount: 12.0,
      details: 'Figs',
    },
    {
      date: `13 / 12 / 19`,
      amount: 13.0,
      details: 'Fruit',
    },
  ],
}

export const allTransactions = [
  {
    date: `12 / 12 / 19`,
    amount: 12.0,
    details: 'Pizza',
  },
  {
    date: `13 / 12 / 19`,
    amount: 13.0,
    details: 'Pie',
  },
  {
    date: `14 / 12 / 19`,
    amount: 14.0,
    details: 'Parma',
  },
  {
    date: `15 / 12 / 19`,
    amount: 15.0,
    details: 'Pineapple',
  },
  {
    date: `16 / 12 / 19`,
    amount: 16.0,
    details: 'Pasta',
  },
  {
    date: `17 / 12 / 19`,
    amount: 17.0,
    details: 'Pickles',
  },
  {
    date: `18 / 12 / 19`,
    amount: 18.0,
    details: 'Pastry',
  },
  {
    date: `12 / 12 / 19`,
    amount: 12.0,
    details: 'Bread',
  },
  {
    date: `13 / 12 / 19`,
    amount: 13.0,
    details: 'Biscuits',
  },
  {
    date: `14 / 12 / 19`,
    amount: 14.0,
    details: 'Bananas',
  },
  {
    date: `15 / 12 / 19`,
    amount: 15.0,
    details: 'Blanched Almonds',
  },
  {
    date: `16 / 12 / 19`,
    amount: 16.0,
    details: 'Burgers',
  },
  {
    date: `17 / 12 / 19`,
    amount: 17.0,
    details: 'Bonoffee',
  },
  {
    date: `18 / 12 / 19`,
    amount: 18.0,
    details: 'Vitamin B',
  },
  {
    date: `12 / 12 / 19`,
    amount: 12.0,
    details: 'Tango',
  },
  {
    date: `13 / 12 / 19`,
    amount: 13.0,
    details: 'Toffee',
  },
  {
    date: `14 / 12 / 19`,
    amount: 14.0,
    details: 'Tamali',
  },
  {
    date: `15 / 12 / 19`,
    amount: 15.0,
    details: 'Towels',
  },
  {
    date: `12 / 12 / 19`,
    amount: 12.0,
    details: 'Figs',
  },
  {
    date: `13 / 12 / 19`,
    amount: 13.0,
    details: 'Fruit',
  },
]
