import {
  setRefreshTokenToStorage,
  removeRefreshTokenFromStorage,
} from 'src/utils/refreshToken'

export const API_LOGIN_REQUEST = 'API_LOGIN_REQUEST'
export const API_LOGIN_SUCCESS = 'API_LOGIN_SUCCESS'
export const API_LOGIN_ERROR = 'API_LOGIN_ERROR'
export const API_LOGOUT_REQUEST = 'API_LOGOUT_REQUEST'
export const API_LOGOUT_SUCCESS = 'API_LOGOUT_SUCCESS'
export const API_LOGOUT_ERROR = 'API_LOGOUT_ERROR'
export const API_REFRESH_REQUEST = 'API_REFRESH_REQUEST'
export const API_REFRESH_SUCCESS = 'API_REFRESH_SUCCESS'
export const API_REFRESH_ERROR = 'API_REFRESH_ERROR'
export const API_RESET_REQUEST = 'API_RESET_REQUEST'
export const API_RESET_SUCCESS = 'API_RESET_SUCCESS'
export const API_RESET_ERROR = 'API_RESET_ERROR'
export const API_NEW_PASSWORD_REQUEST = 'API_NEW_PASSWORD_REQUEST'
export const API_NEW_PASSWORD_SUCCESS = 'API_NEW_PASSWORD_SUCCESS'
export const API_NEW_PASSWORD_ERROR = 'API_NEW_PASSWORD_ERROR'

export function login(rememberMe) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_LOGIN_REQUEST })

    try {
      setTimeout(() => {
        if (rememberMe) {
          setRefreshTokenToStorage('test_token')
        }

        dispatch({
          type: API_LOGIN_SUCCESS,
          data: {
            token: 'test_token',
            refreshToken: 'test_token',
          },
        })
      }, 3000)
    } catch (error) {
      dispatch({
        type: API_LOGIN_ERROR,
      })
    }
  }
}

export function loginWithError() {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_LOGIN_REQUEST })

    setTimeout(() => {
      dispatch({
        type: API_LOGIN_ERROR,
        error: {
          code: 500,
          message: 'Custom error message',
        },
      })
    }, 2000)
  }
}

export function logout() {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_LOGOUT_REQUEST })

    try {
      // clear localStorage here
      removeRefreshTokenFromStorage()

      setTimeout(() => {
        dispatch({
          type: API_LOGOUT_SUCCESS,
        })
      }, 1000)
    } catch (error) {
      dispatch({
        type: API_LOGOUT_ERROR,
      })
    }
  }
}

export function refreshSession() {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_REFRESH_REQUEST })

    try {
      setTimeout(() => {
        dispatch({
          type: API_REFRESH_SUCCESS,
          data: {
            token: 'test_refresh_token',
            refreshToken: 'test_refresh_token',
          },
        })
      }, 3000)
    } catch (error) {
      dispatch({
        type: API_REFRESH_ERROR,
      })
    }
  }
}

export function requestPasswordReset(email) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_NEW_PASSWORD_REQUEST })
    console.log(email)
    try {
      setTimeout(() => {
        dispatch({
          type: API_NEW_PASSWORD_SUCCESS,
        })
      }, 1000)
    } catch (error) {
      dispatch({
        type: API_NEW_PASSWORD_ERROR,
      })
    }
  }
}

export function resetPassword(token, newPassword) {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_RESET_REQUEST })
    console.log(token)
    console.log(newPassword)
    try {
      setTimeout(() => {
        dispatch({
          type: API_RESET_SUCCESS,
        })
      }, 1000)
    } catch (error) {
      dispatch({
        type: API_RESET_ERROR,
      })
    }
  }
}
