import React from 'react'
import PropTypes from 'prop-types'
import { useRoute } from 'react-router5'

import {
  ACCOUNT,
  TRANSFER,
  TRANSACTIONS,
  HOME,
  TRANSACTIONS_DETAILS,
} from 'src/router/routes'
import Login from 'src/containers/Login'
import Account from 'src/containers/Account'
import Transactions from 'src/containers/Transactions'
import Transfers from 'src/containers/Transfers'

const App = () => {
  const { route } = useRoute()
  switch (route.name) {
    case HOME:
      return <Login />
    case ACCOUNT:
      return <Account />
    case TRANSACTIONS:
    case TRANSACTIONS_DETAILS:
      return <Transactions />
    case TRANSFER:
      return <Transfers />
    default:
      return <Login />
  }
}

App.propTypes = {
  route: PropTypes.object,
}

export default App
