import { combineReducers } from 'redux'
import { router5Reducer } from 'redux-router5'

import auth from 'src/modules/auth/reducer'
import error from 'src/modules/error/reducer'
import mock from 'src/modules/mock/reducer'
import progress from 'src/modules/progress/reducer'
import user from 'src/modules/user/reducer'
import users from 'src/modules/users/reducer'
import toast from 'src/modules/toast/reducer'
import ui from 'src/modules/ui/reducer'

const reducers = combineReducers({
  auth,
  error,
  mock,
  progress,
  router: router5Reducer,
  user,
  users,
  toast,
  ui,
})

export default reducers
