import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { bindActionCreators } from 'redux'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import idx from 'idx'
import { withRouter } from 'react-router5'

import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'

import App from 'src/containers/App'
import GlobalStyle from 'src/styles/GlobalStyles'
import Toast from 'src/containers/Toast'

const RootLayout = ({ theme }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <CssBaseline />
          <GlobalStyle />
          <App />
          <Toast />
        </React.Fragment>
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

RootLayout.propTypes = {
  theme: PropTypes.object,
}

export default compose(
  connect(
    (state) => {
      return {
        theme: idx(state, (_) => _.ui.theme),
      }
    },
    (dispatch) => {
      const actions = {}
      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  ),
  withRouter,
  React.memo
)(RootLayout)
