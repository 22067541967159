import getDefaultActions from 'src/utils/getDefaultActions'

export const API_GET_ADMIN_PROFILE = 'API_GET_ADMIN_PROFILE'

export const [
  API_GET_ADMIN_PROFILE_REQUEST,
  API_GET_ADMIN_PROFILE_SUCCESS,
  API_GET_ADMIN_PROFILE_ERROR,
] = getDefaultActions(API_GET_ADMIN_PROFILE)

export function getAdminProfile() {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_ADMIN_PROFILE_REQUEST })

    try {
      dispatch({
        type: API_GET_ADMIN_PROFILE_SUCCESS,
      })
    } catch (error) {
      dispatch({
        type: API_GET_ADMIN_PROFILE_ERROR,
      })
    }
  }
}
