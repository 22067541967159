import { encrypt, decrypt } from './crypto'

export const setRefreshTokenToStorage = (refreshToken) => {
  localStorage.setItem('refreshToken', encrypt(refreshToken))
}

export const getRefreshTokenFromStorage = () => {
  const encryptedToken = localStorage.getItem('refreshToken')
  if (encryptedToken) {
    return decrypt(encryptedToken)
  }
  return ''
}

export const removeRefreshTokenFromStorage = () => {
  localStorage.removeItem('refreshToken')
}
