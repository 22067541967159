import { createSelector } from 'reselect'
import idx from 'idx'

import { SWITCH_THEME_TYPE } from 'src/modules/ui/actions'
import theme, { darkPalette, palette } from 'src/styles/theme'
import { API_GET_ALL_USERS_SUCCESS } from 'src/modules/users/actions'

const initialState = {
  theme,
  users: {
    paginationCount: 0,
  },
}

export const THEME_MODE_LIGHT = 'light'
export const THEME_MODE_DARK = 'dark'

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SWITCH_THEME_TYPE: {
      const { themeType } = action.data
      const { theme } = state

      return {
        ...state,
        theme: {
          ...theme,
          palette: {
            ...theme.palette,
            ...(themeType === THEME_MODE_DARK ? darkPalette : palette),
          },
        },
      }
    }
    case API_GET_ALL_USERS_SUCCESS: {
      const { count } = action.data
      return {
        ...state,
        users: {
          ...state.users,
          paginationCount: count,
        },
      }
    }
    default:
      return state
  }
}

export const getTheme = createSelector(
  (state) => idx(state, (_) => _.theme),
  (theme) => theme
)
