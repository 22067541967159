import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

const LoaderContainer = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 1px;
`

export function LoadingSpinner() {
  return (
    <LoaderContainer>
      <CircularProgress />
    </LoaderContainer>
  )
}

export default LoadingSpinner
