import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useRouter } from 'react-router5'
import {
  TRANSACTIONS,
  TRANSFER,
  HOME,
  TRANSACTIONS_DETAILS,
} from 'src/router/routes'
import { Typography } from '@material-ui/core'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import { accountDetails } from 'src/utils/accounts'
import { StyledButton } from 'src/components/atoms/StyledButton/StyledButton'
import { waitTime } from 'src/utils/waitTime'
import money from 'src/assets/images/flyingmoney.gif'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 60%;
  min-height: 100vh;
  margin: auto;
  justify-content: center;
  align-content: center;
  background-color: #e942f5;
`

const BankName = styled(Typography).attrs({ variant: 'h1' })`
  && {
    font-size: 100px;
    font-weight: 900;
    align-self: center;
    padding-bottom: 50px;
    font-family: 'Comic Sans MS';
    color: #42f5b0;
    margin-right: 50px;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 40px 0;
  width: 100%;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const AccountContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100px;
  width: 900px;
  border-radius: 25px;
  background-color: #42f5b0;
  justify-content: space-between;
  background-image: url(${money});
  background-repeat: repeat-x;
  margin: 10px 0;
  &:hover {
    cursor: pointer;
  }
`

const AccountText = styled(Typography).attrs({ variant: 'h4' })`
  && {
    color: #e942f5;
    align-self: center;
    padding: 0 40px;
    min-width: 20%;
    font-weight: bold;
    font-family: 'Comic Sans MS';
  }
`

const AccountNumber = styled(Typography).attrs({ variant: 'h4' })`
  && {
    color: #e942f5;
    align-self: center;
    padding: 0 40px;
    min-width: 40%;
    font-weight: bold;
    font-family: 'Comic Sans MS';
  }
`

const Account = () => {
  const [loadingTransfer, setLoadingTransfer] = useState(false)
  const [loadingTransactions, setLoadingTransactions] = useState(false)
  const [loading, setLoading] = useState(false)
  const router = useRouter()

  const handleTransfer = () => {
    setLoadingTransfer(true)
    setTimeout(() => router.navigate(TRANSFER), waitTime())
  }

  const handleTransaction = () => {
    setLoadingTransactions(true)
    setTimeout(() => router.navigate(TRANSACTIONS), waitTime())
  }

  const handleLogout = () => {
    router.navigate(HOME)
  }

  const handleIndividualTransactions = (index) => {
    setLoading(true)
    setTimeout(() => {
      router.navigate(TRANSACTIONS_DETAILS, {
        id: index,
      })
    }, waitTime())
  }

  return (
    <Container>
      <BankName>roamBank</BankName>
      <Row />
      <Column>
        {accountDetails.map((account, index) => (
          <AccountContainer
            id="accountContainer"
            key={account.accountName}
            onClick={() => handleIndividualTransactions(index + 1)}
          >
            {loading ? (
              <LoadingSpinner />
            ) : (
              <Row id="accountLine">
                <AccountText>{account.accountName}</AccountText>
                <AccountNumber>{account.accountNumber}</AccountNumber>
                <AccountText>{account.type}</AccountText>
                <AccountText>${account.balance}</AccountText>
              </Row>
            )}
          </AccountContainer>
        ))}
      </Column>
      <Row>
        <StyledButton onClick={handleTransfer}>
          {loadingTransfer ? <LoadingSpinner /> : 'Transfer'}
        </StyledButton>
        <StyledButton onClick={handleTransaction}>
          {loadingTransactions ? <LoadingSpinner /> : 'Transactions'}
        </StyledButton>
        <StyledButton onClick={handleLogout}>Logout</StyledButton>
      </Row>
    </Container>
  )
}

Account.propTypes = {
  router: PropTypes.object,
}

export default React.memo(Account)
