import { createSelector } from 'reselect'
import idx from 'idx'

import {
  API_LOGIN_SUCCESS,
  API_LOGOUT_SUCCESS,
  API_REFRESH_SUCCESS,
} from 'src/modules/auth/actions'
import { getRefreshTokenFromStorage } from 'src/utils/refreshToken'

const initialState = {
  token: '',
  refreshToken: '',
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case API_REFRESH_SUCCESS:
    case API_LOGIN_SUCCESS: {
      const { token, refreshToken } = action.data

      return {
        ...state,
        token,
        refreshToken,
      }
    }
    case API_LOGOUT_SUCCESS: {
      return {}
    }
    default:
      return state
  }
}

export const getToken = createSelector(
  (state) => idx(state, (_) => _.auth.token),
  (token) => token
)

export const getRefreshToken = createSelector(
  (state) => idx(state, (_) => _.auth.refreshToken),
  (refreshToken) => refreshToken || getRefreshTokenFromStorage()
)
