import { createMuiTheme } from '@material-ui/core/styles'

const fontFamily = "'ProximaNova', sans-serif"

const fontWeightBold = 700
const fontWeightMedium = 500
const fontWeightRegular = 400

const spacingUnit = 8
export const spacing = {
  unit: spacingUnit,
  xxl: spacingUnit * 10, // 80
  xl: spacingUnit * 6, // 48
  lg: spacingUnit * 4, // 32
  md: spacingUnit * 2, // 16
  sm: spacingUnit, // 8
  xs: spacingUnit / 2, // 4
}

export const darkPalette = {
  primary: {
    main: '#221266',
    contrastText: '#fff',
  },
  secondary: {
    main: '#801313',
    contrastText: '#fff',
  },
  tertiary: {
    accent: '#b71c1c',
  },
  background: {
    light: '#F5F7FA',
    grey: '#F8F8F8',
    default: '#fff',
    dark: '#302e34',
    paper: '#424242',
  },
  disabled: {
    main: '#949494',
    light: '#f4f4f4',
  },
  success: {
    main: '#04CB94',
    light: '#CFFFD2',
    xlight: '#F5FDF7',
  },
  warning: {
    main: '#ff8721',
    xlight: '#fffaea',
  },
  error: {
    main: '#FF2151',
    light: '#FBE3EE',
    xlight: '#fcedf4',
  },
  text: {
    primary: '#ffffff',
    secondary: '#949494',
  },
  divider: '#ECECEC',
  tooltip: {
    main: '#0b1f31',
    secondary: '#FFFFFF',
  },
  toast: {
    main: '#4B87FF',
    error: '#D2003C',
    success: '#84D584',
  },
  type: 'dark',
}

export const palette = {
  primary: {
    main: '#4B87FF',
    light: '#6d9eff',
    xlight: '#f2f6ff',
    dark: '#4B87FF',
    contrastText: '#fff',
  },
  secondary: {
    main: '#DBE7FF',
    light: '#6d9eff',
    dark: '#c9dbff',
    contrastText: '#4B87FF',
  },
  tertiary: {
    accent: '#E4E4EA',
    light: '#eff2f9',
    main: '#828797',
    heavy: '#656977',
    dark: '#201F20',
  },
  background: {
    light: '#F5F7FA',
    grey: '#F8F8F8',
    default: '#fff',
    dark: '#302e34',
    paper: '#fff',
  },
  disabled: {
    main: '#949494',
    light: '#f4f4f4',
  },
  success: {
    main: '#04CB94',
    light: '#CFFFD2',
    xlight: '#F5FDF7',
  },
  warning: {
    main: '#ff8721',
    xlight: '#fffaea',
  },
  error: {
    main: '#FF2151',
    light: '#FBE3EE',
    xlight: '#fcedf4',
  },
  text: {
    primary: '#000000',
    secondary: '#949494',
    contrast: '#FFFFFF',
  },
  divider: '#ECECEC',
  tooltip: {
    main: '#0b1f31',
    secondary: '#FFFFFF',
  },
  toast: {
    main: '#4B87FF',
    error: '#D2003C',
    success: '#84D584',
  },
  type: 'light',
}

const transition =
  '250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'

const theme = createMuiTheme({
  palette,
  spacing,
  typography: {
    useNextVariants: true,
  },
  weights: {
    fontWeightBold,
    fontWeightMedium,
    fontWeightRegular,
  },
  status: {
    danger: 'orange',
  },
  props: {
    MuiWithWidth: {
      initialWidth: 'xs',
    },
  },
  transition,
})

// We can only use typography function such as pxToRem after the createMuiTheme
theme.typography = {
  ...theme.typography,
  fontFamily: fontFamily,
  fontFamilySecondary: fontFamily,
  h1: {
    ...theme.typography.h1,
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(40),
    lineHeight: theme.typography.pxToRem(54),
  },
  h2: {
    ...theme.typography.h2,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.pxToRem(43),
  },
  h3: {
    ...theme.typography.h3,
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(32),
  },
  h4: {
    ...theme.typography.h4,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24),
  },
  h5: {
    ...theme.typography.h5,
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
  },
  h6: {
    ...theme.typography.h6,
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
  },
  subtitle1: {
    ...theme.typography.subtitle1,
  },
  subtitle2: {
    ...theme.typography.subtitle2,
  },
  body1: {
    ...theme.typography.body1,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
  },
  body2: {
    ...theme.typography.body2,
  },
  button: {
    ...theme.typography.button,
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
  },
  caption: {
    ...theme.typography.caption,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(16),
  },
  overline: {
    ...theme.typography.overline,
  },
}

// Overrides are assigned here so we can use theme.breakpoints
// Override cake ui components here based off classes api
theme.overrides = {
  MuiCard: {
    root: {
      borderRadius: 0,
      border: `1px solid ${palette.divider}`,
      boxShadow: 'none',
    },
  },
  MuiChip: {
    root: {
      marginRight: spacing.sm,
      color: palette.primary.main,
      height: 28,
      backgroundColor: 'transparent',
      border: `1px solid ${palette.primary.main}`,
    },
  },
  // MuiCheckbox: {
  //   root: {
  //     width: 24,
  //     height: 24,
  //     marginRight: 5,
  //     '& svg': {
  //       width: 15,
  //       height: 15,
  //     },
  //   },
  // },
  MuiCollapse: {
    entered: {
      height: 'auto',
      overflow: 'visible',
    },
  },
  MuiButton: {
    root: {
      textTransform: 'none',
      borderRadius: 50,
      fontFamily,
      fontSize: '.85rem',
      fontWeight: fontWeightMedium,
      letterSpacing: theme.typography.pxToRem(1.25),
      minHeight: 44,
    },
    contained: {
      boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14)',
      minWidth: 100,
    },
    outlined: {
      borderColor: palette.secondary.dark,
      color: palette.secondary.dark,
    },
  },
  MuiLink: {
    // link: {
    //   fontStyle: 'normal',
    //   color: palette.primary.main,
    // },
  },
  MuiDialog: {
    paper: {
      borderRadius: 10,
      minWidth: 460,
      padding: 0,
    },
  },
  // MuiDialogContent: {
  //   root: {
  //     padding: '10px 60px',
  //     overflowY: 'visible',
  //   },
  // },
  // MuiDialogTitle: {
  //   root: {
  //     padding: 40,
  //     '& h2': {
  //       textAlign: 'center',
  //       fontSize: theme.typography.pxToRem(32),
  //     },
  //   },
  // },
  MuiDialogActions: {
    root: {
      padding: '20px 30px 40px 30px',
    },
    action: {
      margin: '0 10px',
    },
  },
  MuiSwitch: {
    switchBase: {
      height: 36,
    },
  },
  MuiSelect: {
    icon: {
      color: 'inherit',
    },
    select: {
      color: theme.palette.primary.main,
    },
  },
  MuiMenuItem: {
    root: {
      fontSize: theme.typography.pxToRem(14),
      textTransform: 'none',
      fontFamily,
      color: theme.palette.text.main,
      letterSpacing: theme.typography.pxToRem(0.88),
      padding: '6px 24px',
      minWidth: 100,
      // Hide empty label value
      '&[data-value=""]': {
        display: 'none',
      },
    },
  },
  MuiTab: {
    root: {
      textTransform: 'none',
      minHeight: 56,
      [theme.breakpoints.up('xs')]: {
        minWidth: 0,
        marginLeft: 25,
        marginRight: 25,
      },
      '&:first-of-type': {
        marginLeft: 20,
      },
    },
    label: {
      fontSize: theme.typography.pxToRem(18),
      color: palette.primary.contrastText,
      fontWeight: fontWeightRegular,
    },
    textColorPrimary: {
      fontWeight: fontWeightBold,
      color: palette.primary.contrastText,
    },
    disabled: {
      opacity: 0.3,
    },
    labelContainer: {
      [theme.breakpoints.up('xs')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  MuiFormControlLabel: {
    root: {
      marginLeft: 0,
    },
  },
  MuiTableCell: {
    head: {
      color: palette.text.primary,
      fontSize: theme.typography.pxToRem(16),
      fontWeight: fontWeightMedium,
      padding: 10,
    },
    body: {
      color: palette.text.primary,
      fontSize: theme.typography.pxToRem(15),
      fontWeight: fontWeightRegular,
      padding: 10,
      position: 'relative',
    },
  },
  MuiTableHead: {
    root: {
      background: theme.palette.background.grey,
    },
  },
  MuiPrivateTextarea: {
    textarea: {
      width: 'auto',
    },
  },
}

export default theme
