import { API_GET_ADMIN_PROFILE_SUCCESS } from './actions'

export const initialState = {
  email: 'andrew+groupadmin13@roamltd.com',
  firstName: 'Andrew',
  lastName: 'groupadmn',
  role: 'groupAdmin',
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case API_GET_ADMIN_PROFILE_SUCCESS: {
      const { email, firstName, lastName, role } = action.data

      return {
        ...state,
        email,
        firstName,
        lastName,
        role,
      }
    }
    default:
      return state
  }
}
