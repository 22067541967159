export const LOGIN = 'login'
export const HOME = 'home'
export const ACCOUNT = 'account'
export const TRANSFER = 'transfer'
export const TRANSACTIONS = 'transactions'
export const TRANSACTIONS_DETAILS = 'transactions details'

const routes = [
  { name: LOGIN, path: '/login' },
  { name: HOME, path: '/' },
  { name: ACCOUNT, path: '/account' },
  {
    name: TRANSACTIONS,
    path: '/transactions',
  },
  {
    name: TRANSACTIONS_DETAILS,
    path: '/transactions/:id',
  },
  { name: TRANSFER, path: '/transfer' },
]

// Public routes bypass the authentication middleware
export const publicRoutes = [LOGIN, ACCOUNT]

export const isPrivateRoute = (routeName) =>
  publicRoutes.indexOf(routeName) === -1

export default routes
