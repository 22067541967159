import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router5'

import {
  Snackbar,
  SnackbarContent,
  withStyles,
  IconButton,
} from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import InfoIcon from '@material-ui/icons/Info'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'

import { closeToast } from 'src/modules/toast/actions'

export const styles = (theme) => {
  const content = {
    maxWidth: '100%',
    width: '100%',
    padding: `${theme.spacing.sm}px ${theme.spacing.xl}px`,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.weights.fontWeightBold,
    borderRadius: 0,
    minHeight: 60,
  }
  return {
    root: {
      width: '100%',
    },
    success: {
      ...content,
      backgroundColor: theme.palette.toast.success,
    },
    error: {
      ...content,
      backgroundColor: theme.palette.toast.error,
    },
    info: {
      ...content,
      backgroundColor: theme.palette.toast.main,
    },
    icon: {
      fontSize: 20,
    },
    iconSuccess: {
      marginRight: theme.spacing.md,
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    cancelButton: {
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
      backgroundColor: theme.palette.background.default,
      marginRight: theme.spacing.md,
    },
    button: {
      minHeight: 20,
    },
  }
}

export const TOAST_TYPE_ERROR = 'error'
export const TOAST_TYPE_SUCCESS = 'success'
export const TOAST_TYPE_INFO = 'info'

const Toast = ({ classes, actions, variant, content, open }) => {
  const handleClose = () => {
    actions.closeToast()
  }

  const actionButton = (
    <IconButton
      key="close"
      aria-label="Close"
      color="inherit"
      className={classes.close}
      onClick={handleClose}
    >
      <CloseIcon className={classes.icon} />
    </IconButton>
  )

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={variant === TOAST_TYPE_INFO ? null : 2000}
      onClose={variant === TOAST_TYPE_INFO ? null : handleClose}
      classes={{ root: classes.root }}
    >
      <SnackbarContent
        className={classes[variant]}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            {variant === TOAST_TYPE_ERROR && (
              <WarningIcon className={classes.iconSuccess} />
            )}
            {variant === TOAST_TYPE_SUCCESS && (
              <CheckCircleIcon className={classes.iconSuccess} />
            )}
            {variant === TOAST_TYPE_INFO && (
              <InfoIcon className={classes.iconSuccess} />
            )}
            {content}
          </span>
        }
        action={actionButton}
      />
    </Snackbar>
  )
}

Toast.propTypes = {
  classes: PropTypes.object,
  actions: PropTypes.object,
  content: PropTypes.any,
  variant: PropTypes.oneOf([
    TOAST_TYPE_SUCCESS,
    TOAST_TYPE_ERROR,
    TOAST_TYPE_INFO,
  ]),
  open: PropTypes.bool,
}

export default compose(
  connect(
    (state) => {
      return {
        ...state.toast,
      }
    },
    (dispatch) => {
      const actions = { closeToast }
      return {
        actions: bindActionCreators(actions, dispatch),
      }
    }
  ),
  withRouter,
  React.memo
)(withStyles(styles)(Toast))
