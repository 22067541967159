import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useRouter } from 'react-router5'
import { Typography, Input } from '@material-ui/core'

import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import { ACCOUNT } from 'src/router/routes'
import { StyledButton } from 'src/components/atoms/StyledButton/StyledButton'
import { waitTime } from 'src/utils/waitTime'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 60%;
  min-height: 100vh;
  margin: auto;
  justify-content: center;
  align-content: center;
  background-color: #e942f5;
`

const BankName = styled(Typography).attrs({ variant: 'h1' })`
  && {
    font-size: 100px;
    font-weight: 900;
    align-self: center;
    padding-bottom: 50px;
    color: #42f5b0;
    font-family: 'Comic Sans MS';
    margin-left: 100px;
  }
`

const StyledInput = styled(Input)`
  && {
    width: 250px;
    height: 50px;
    margin: 0 50px 40px 50px;s
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const InputName = styled(Typography).attrs({ variant: 'h3' })`
  && {
    margin: 0 50px;
    color: #42f5b0;
    font-family: 'Comic Sans MS';
  }
`

const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const router = useRouter()

  const [loading, setLoading] = useState(false)

  const handleLogin = () => {
    if (
      username === loginDetails.username &&
      password === loginDetails.password
    ) {
      setLoading(true)
      setTimeout(() => router.navigate(ACCOUNT), waitTime())
    }
  }

  const handleUsername = (event) => {
    setUsername(event.target.value)
  }

  const handlePassword = (event) => {
    setPassword(event.target.value)
  }

  const loginDetails = { username: 'username', password: 'password' }

  return (
    <Container>
      <BankName>roamBank</BankName>
      <Row>
        <Column>
          <InputName>Username</InputName>
          <StyledInput
            id="username"
            value={username}
            onChange={handleUsername}
          />
        </Column>
        <Column>
          <InputName>Password</InputName>
          <StyledInput
            id="password"
            type="password"
            value={password}
            onChange={handlePassword}
          />
        </Column>
      </Row>

      <StyledButton id="submit" onClick={handleLogin}>
        {loading ? <LoadingSpinner /> : 'Login'}
      </StyledButton>
    </Container>
  )
}

Login.propTypes = {
  router: PropTypes.object,
}

export default React.memo(Login)
