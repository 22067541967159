import routes, { HOME } from './routes'

import browserPlugin from 'router5-plugin-browser'
import createRouter from 'router5'
// import { isUserAuthenticated } from './authMiddleware'
import { reduxPlugin } from 'redux-router5'

export default function configureRouter(store) {
  const router = createRouter(routes, {
    defaultRoute: HOME,
  })

  // Plugins
  router.usePlugin(
    browserPlugin({
      useHash: false,
      base: '',
    }),
    reduxPlugin(store.dispatch)
  )

  // Middlewares
  // router.useMiddleware(isUserAuthenticated(store))

  return router
}
