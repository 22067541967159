import { applyMiddleware, compose, createStore } from 'redux'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import { createEpicMiddleware } from 'redux-observable'
import thunkMiddleware from 'redux-thunk'

import reducers from 'src/modules/reducers'
import { rootEpic } from 'src/modules/epics'

const observableMiddleware = createEpicMiddleware()
export const middlewares = [
  // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
  reduxImmutableStateInvariant(),
  observableMiddleware,
  // thunk middleware can also accept an extra argument to be passed to each thunk action
  // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
  thunkMiddleware,
]

export default function configureStore(initialState) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // add support for Redux dev tools

  const store = createStore(
    reducers,
    { ...(initialState || {}) },
    composeEnhancers(applyMiddleware(...middlewares))
  )

  observableMiddleware.run(rootEpic)
  return store
}
