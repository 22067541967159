import { API_MOCK_CANCEL, API_MOCK_REQUEST, API_MOCK_SUCCESS } from './actions'

import { createSelector } from 'reselect'
import idx from 'idx'

export default function userReducer(state = {}, action) {
  switch (action.type) {
    case API_MOCK_REQUEST: {
      return {
        ...state,
        [action.delay]: true,
      }
    }
    case API_MOCK_SUCCESS: {
      return {
        ...state,
        [action.delay]: false,
      }
    }
    case API_MOCK_CANCEL: {
      return Object.keys(state)
        .filter((callDelayKey) => {
          return state[callDelayKey] === false
        })
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: state[key],
          }
        }, {})
    }
    default:
      return state
  }
}

export const getToken = createSelector(
  (state) => idx(state, (_) => _.auth.token),
  (token) => token
)
