// import { getUsers } from 'src/utils/sauce/sauce-api-user'
import { responseExample } from 'src/modules/users/mocks'
import normaliseResponse from 'src/utils/normaliseResponse'
import getDefaultActions from 'src/utils/getDefaultActions'
import idx from 'idx'

export const API_GET_ALL_USERS = 'API_GET_ALL_USERS'

export const [
  API_GET_ALL_USERS_REQUEST,
  API_GET_ALL_USERS_SUCCESS,
  API_GET_ALL_USERS_ERROR,
] = getDefaultActions(API_GET_ALL_USERS)

export function getAllUsers() {
  return async function dispatcher(dispatch) {
    dispatch({ type: API_GET_ALL_USERS_REQUEST })

    try {
      setTimeout(() => {
        const response = responseExample
        if (idx(response, (_) => _.data.data)) {
          const { data } = normaliseResponse(response.data.data, 'uuid')
          dispatch({
            type: API_GET_ALL_USERS_SUCCESS,
            data,
          })
        } else {
          dispatch({
            type: API_GET_ALL_USERS_ERROR,
            message: 'Invalid response',
          })
        }
      }, 2000)
    } catch (error) {
      dispatch({
        type: API_GET_ALL_USERS_ERROR,
      })
    }
  }
}
