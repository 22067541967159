import { API_GET_ALL_USERS_SUCCESS } from './actions'
import { createSelector } from 'reselect'
import idx from 'idx'

const initialState = {}

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case API_GET_ALL_USERS_SUCCESS: {
      return {
        ...state,
        ...action.data,
      }
    }
    default:
      return state
  }
}

export const getUsers = createSelector(
  (state) => idx(state, (_) => _.users),
  (users) => Object.values(users)
)
