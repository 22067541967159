import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useRouter } from 'react-router5'
import { ACCOUNT, HOME } from 'src/router/routes'
import { Typography } from '@material-ui/core'
import { transactions, allTransactions } from 'src/utils/accounts'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import { StyledButton } from 'src/components/atoms/StyledButton/StyledButton'
import { waitTime } from 'src/utils/waitTime'
import money from 'src/assets/images/flyingmoney.gif'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 60%;
  min-height: 100vh;
  margin: auto;
  justify-content: center;
  align-content: center;
  background-color: #e942f5;
`

const BankName = styled(Typography).attrs({ variant: 'h1' })`
  && {
    font-size: 100px;
    font-weight: 900;
    align-self: center;
    padding-bottom: 40px;
    font-family: 'Comic Sans MS';
    color: #42f5b0;
    margin-left: 200px;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 40px 0;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const TransactionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 1200px;
  width: 900px;
  border-radius: 25px;
  background-color: #42f5b0;
  justify-content: space-between;
  margin: 10px 0;
  overflow-y: scroll;
  background-image: url(${money});
  background-repeat: repeat-xy;
`

const AccountText = styled(Typography).attrs({ variant: 'h4' })`
  && {
    color: white;
    align-self: center;
    padding: 0 40px;
    min-width: 33%;
    font-weight: bold;
    align-self: center;
    color: #e942f5;
    font-family: 'Comic Sans MS';
  }
`

const Account = () => {
  const [loading, setLoading] = useState(false)
  const router = useRouter()

  const handleAccounts = () => {
    setLoading(true)
    setTimeout(() => router.navigate(ACCOUNT), waitTime())
  }

  const handleLogout = () => {
    setLoading(true)
    setTimeout(() => router.navigate(HOME), waitTime())
  }
  const address = window.location.href
  const params = address[address.length - 1]
  const paramChecker = () =>
    params && params !== 's' ? transactions[params] : allTransactions
  return (
    <Container>
      <BankName>roamBank</BankName>
      <Row />
      <Column>
        <TransactionsContainer id="transactionsContainer">
          {paramChecker().map((account) => (
            <Row
              id="transactionsLine"
              key={`${account.date} + ${account.details}`}
            >
              <AccountText>{account.date}</AccountText>
              <AccountText>{account.details}</AccountText>
              <AccountText>${account.amount}</AccountText>
            </Row>
          ))}
        </TransactionsContainer>
      </Column>
      <Row>
        <StyledButton onClick={handleAccounts}>
          {loading ? <LoadingSpinner /> : 'Accounts'}
        </StyledButton>
        <StyledButton onClick={handleLogout}>Logout</StyledButton>
      </Row>
    </Container>
  )
}

Account.propTypes = {
  router: PropTypes.object,
}

export default React.memo(Account)
